import type { User } from '@kudyfinancials/livre';

const sprites = [
  'identicon',
  'initials',
  'bottts',
  'avataaars',
  'micah',
  'pixel-art',
  'open-peeps',
  'fun-emoji',
  'lorelei',
  'lorelei-neutral',
  'notionists',
  'notionists-neutral',
  'personas',
];

const stringToAvatar = (user: User) => {
  const sprite = sprites[(sprites.length * Math.random()) | 0];
  const fname = user?.fname || 'kudy';
  return (
    user?.avatar ||
    `https://api.dicebear.com/7.x/${sprite}/svg?seed=${fname.toLowerCase()}`
  );
};
export default stringToAvatar;
