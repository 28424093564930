import { usePermissions } from '@/hooks/usePermissions';
import Avatar from './Avatar';
import { useAuth } from '@/context/Auth';

const ClientHeader = ({ user, onClick, nav }) => {
  const { auth } = useAuth();
  const { isSuperAdmin } = usePermissions();

  return (
    <div
      className={`flex items-center cursor-pointer ${
        nav ? 'mt-3 sm:mt-14' : ''
      } text-white`}
    >
      <Avatar user={user} onClick={onClick} />
      <div
        className={`xl:flex ${
          nav ? 'flex lg:hidden' : 'hidden'
        } flex-col ml-10p`}
      >
        <span className="capitalize cursor-pointer text-14" onClick={onClick}>
          {auth?.user?.fname} {auth?.user?.lname}
        </span>
        <span className="opacity-50 text-12">
          {isSuperAdmin ? 'Super Admin' : 'Admin'}
        </span>
      </div>
    </div>
  );
};

export default ClientHeader;
