import twMerge from '@/utils/twMerge';
import type { ReactNode } from 'react';

type TableItemProps = {
  item: ReactNode;
  className?: string;
};

export const TableItem = ({ item, className }: TableItemProps) => (
  <div className={twMerge('p-4 border-b-0.5 border-loblolly', className)}>
    {item}
  </div>
);
