import { lazy } from 'react';
import MonthlyReport from '../components/MonthlyReport';
import PurgeInvestors from '../components/PurgeInvestors';
import { ClientProfile } from '@/utils/assets/Profile';
import { Lock } from '@/utils/assets/Lock';
import { Exchange } from '@/utils/assets/Exchange';
import { Purge } from '@/utils/assets/Purge';
import { FundsIcon, KreditIcon, OverviewIcon } from '@kudyfinancials/livre';
import { usePermissions } from '@/hooks/usePermissions';
import Overview from '@/pages/Overview/Overview';
import Contract from '@/utils/assets/Contract';

const Accounts = lazy(() => import('../pages/Accounts'));
const AdminProfile = lazy(() => import('../pages/AdminProfile'));
const ExchangeRate = lazy(() => import('../pages/ExchangeRate'));
const FundsPage = lazy(() => import('../pages/Funds'));
const ContractAgreement = lazy(
  () => import('../pages/Contracts/ContractAgreement')
);
const KreditPage = lazy(() => import('../pages/Kredit'));
const FundDetails = lazy(() => import('../components/Funds/FundDetails'));
const CreateFund = lazy(() => import('../components/Funds/CreateFund'));
const Account = lazy(() => import('../pages/Accounts/Account'));
const AccessLevel = lazy(() => import('../pages/AccessLevel'));
const AddRole = lazy(() => import('../pages/AccessLevel/AddRole'));
const UpdateRole = lazy(() => import('@/pages/AccessLevel/UpdateRole'));

export const useAppRoutes = () => {
  const { hasPermission } = usePermissions();

  const overviewRoute = {
    path: '/overview',
    label: 'Overview',
    icon: OverviewIcon,
    Component: Overview,
  };

  const routeConfig = [
    {
      path: '/accounts',
      label: 'Accounts',
      icon: ClientProfile,
      Component: Accounts,
      requiredPermissions: ['account:-:*', 'account:-:read', 'account:-:write'],
    },
    {
      path: '/funds',
      label: 'Funds',
      icon: FundsIcon,
      Component: FundsPage,
      requiredPermissions: ['funds:-:*', 'funds:-:read', 'funds:-:write'],
    },
    {
      path: '/kredit',
      label: 'Kudy Kredit',
      icon: KreditIcon,
      Component: KreditPage,
      requiredPermissions: [
        'kudykredit:-:*',
        'kudykredit:-:read',
        'kudykredit:-:write',
      ],
    },
    {
      path: '/contracts',
      label: 'Contracts',
      icon: Contract,
      Component: ContractAgreement,
      requiredPermissions: [
        'contract:-:*',
        'contract:-:read',
        'contract:-:write',
      ],
    },
    {
      path: '/access',
      label: 'Access Level',
      icon: Lock,
      Component: AccessLevel,
      requiredPermissions: [
        'role:-:*',
        'role:user:*',
        'role:domain:*',
        'role:user:read',
        'role:domain:read',
        'role:user:write',
        'role:domain:write',
      ],
    },
    {
      path: '/exchange-rates',
      label: 'Exchange Rates',
      icon: Exchange,
      Component: ExchangeRate,
      requiredPermissions: [
        'exchange:-:*',
        'exchange:-:read',
        'exchange:-:write',
      ],
    },
    {
      path: '#',
      label: 'Monthly Report',
      icon: KreditIcon,
      Component: MonthlyReport,
      requiredPermissions: [
        'fund:statement:*',
        'fund:statement:read',
        'fund:statement:write',
      ],
    },
    {
      path: '#',
      label: 'Purge Clients',
      icon: Purge,
      Component: PurgeInvestors,
      requiredPermissions: ['user:-:*', 'user:-:read', 'user:-:write'],
    },
    {
      path: '/profile',
      Component: AdminProfile,
      requiredPermissions: ['user:-:*', 'user:-:read'],
    },
    {
      path: '/funds/create-fund',
      Component: CreateFund,
      requiredPermissions: ['fund:-:*', 'fund:-:write'],
    },
    {
      path: '/funds/details/:fundId',
      Component: FundDetails,
      requiredPermissions: ['fund:-:*', 'fund:-:read', 'fund:-:write'],
    },
    {
      path: '/accounts/:accountId',
      Component: Account,
      requiredPermissions: ['account:-:*', 'account:-:read', 'account:-:write'],
    },
    {
      path: '/access/new-role',
      Component: AddRole,
      requiredPermissions: [
        'role:-:*',
        'role:user:*',
        'role:domain:*',
        'role:user:write',
        'role:domain:write',
      ],
    },
    {
      path: '/access/update-role/:roleId',
      Component: UpdateRole,
      requiredPermissions: [
        'role:-:*',
        'role:user:*',
        'role:domain:*',
        'role:user:write',
        'role:domain:write',
      ],
    },
  ];

  const dashboardRoutes = [
    { path: overviewRoute.path, Component: overviewRoute.Component },
    ...routeConfig.map(({ path, Component }) => ({
      path,
      Component,
    })),
  ];

  const linkItems = [
    {
      to: overviewRoute.path,
      label: overviewRoute.label,
      icon: overviewRoute.icon,
    },
    ...routeConfig
      .filter(
        (route) => route.label && hasPermission(route.requiredPermissions)
      )
      .map(({ path, label, icon, Component }) => ({
        to: path === '#' ? '#' : `${path}`,
        label: label!,
        icon: icon!,
        component: path === '#' && <Component />,
      })),
  ];

  return { dashboardRoutes, linkItems };
};
