import { useState } from 'react';
import {
  autoUpdate,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  flip,
} from '@floating-ui/react';
import twMerge from '@/utils/twMerge';

const Dropdown = ({ options, icon: IconComponent, onChange, selected }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleOptionClick = (option) => {
    onChange(option);
    setShowDropdown(false);
  };

  const {
    floatingStyles,
    refs: { setReference, setFloating },
    context,
  } = useFloating({
    whileElementsMounted: autoUpdate,
    open: showDropdown,
    placement: 'bottom-end',
    strategy: 'fixed',
    onOpenChange: setShowDropdown,
    middleware: [flip()],
  });

  const dismiss = useDismiss(context);
  const click = useClick(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
  ]);

  return (
    <>
      <div
        className={twMerge(
          'flex items-center w-full justify-center',
          selected && 'justify-between',
          options.length === 0 ? 'cursor-not-allowed' : 'cursor-pointer'
        )}
        ref={setReference}
        {...getReferenceProps()}
      >
        {selected}

        <IconComponent />
      </div>
      {showDropdown && (
        <ul
          ref={setFloating}
          style={{
            ...floatingStyles,
          }}
          {...getFloatingProps()}
          className="bg-white z-10 rounded-sm shadow-xl text-sm whitespace-nowrap w-fit"
        >
          {options.map((option) => (
            <li
              key={option.label}
              className="py-2.5 px-4 hover:bg-jungleGreen-20 active:bg-jungleGreen-30 cursor-pointer"
              onClick={() => handleOptionClick(option)}
              style={{ color: option.textColor }}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default Dropdown;
